// @ts-check

import React, { Component } from 'react';
import { Button } from './button';
import { Container } from './container';
import { getCommentsPost, commentPost, likeComment, blameComment } from '../api';
import SlideToggle from 'react-slide-toggle';
import { Comment } from './comment';
import { Spinner } from './spinner';
import { Recaptcha } from './recaptcha';

export class CommentSection extends Component {
  state = {
    comments: [],
    ownComment: '',
    email: 'email',
    emailIsValid: true,
    captchaToken: undefined,
    captchaError: false,
    isSending: false,
    openBlameDialogSlug: '',
    blameCaptchaToken: undefined,
    blameCaptchaError: undefined,
    blameReason: '',
    blameEmailInput: '',
    blameLoading: false,
    windowWidth: 0
  };

  componentDidMount() {
    this.fetchComments();
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    const { innerWidth: width } = window;
    this.setState({ windowWidth: width });
  };

  fetchComments() {
    getCommentsPost({ slug: this.props.slug }).then(([comments, err]) => {
      if (!err && Array.isArray(comments)) {
        this.setState({ comments });
      }
    });
  }

  updateOwnComment = e => {
    this.setState({ ownComment: e.target.value });
  };

  submitComment = e => {
    if (this.state.ownComment.length < 1) {
      return;
    }

    if (this.state.captchaToken === null) {
      this.setState({
        captchaError: 'Das Captcha ist abgelaufen. Bitte benutzen Sie es erneut.'
      });
      return;
    } else if (this.state.captchaToken === undefined) {
      this.setState({
        captchaError: 'Bitte benutzen Sie das Captcha-Element.'
      });
      return;
    }

    this.setState({ captchaError: false, isSending: true });

    commentPost({
      slug: this.props.slug,
      comment: this.state.ownComment,
      email: this.state.email,
      captchaToken: this.state.captchaToken
    }).then(([_, err]) => {
      if (!err) {
        this.setState({
          ownComment: '',
          captchaToken: undefined,
          captchaError: undefined,
          isSending: false
        });
        this.fetchComments();
      } else {
        this.setState({
          captchaError: err.message,
          isSending: false
        });
      }
    });
  };

  likeComment = slug => {
    if (this.props.interaction) {
      likeComment({ slug }).then(([_, err]) => {
        if (!err) {
          this.fetchComments();
        } else {
          console.error(err);
        }
      });
    }
  };

  blameComment = () => {
    if (this.state.blameCaptchaToken === null) {
      this.setState({
        blameCaptchaError: 'Das Captcha ist abgelaufen. Bitte benutzen Sie es erneut.'
      });
      return;
    } else if (this.state.blameCaptchaToken === undefined) {
      this.setState({
        blameCaptchaError: 'Bitte benutzen Sie das Captcha-Element.'
      });
      return;
    }

    this.setState({ blameLoading: true });
    blameComment({
      slug: this.state.openBlameDialogSlug,
      reason: this.state.blameReason,
      email: this.state.blameEmailInput,
      captchaToken: this.state.blameCaptchaToken
    })
      .then(([result, error]) => {
        console.log(error);
        if (!error) {
          this.setState({
            openBlameDialogSlug: '',
            blameReason: '',
            blameCaptchaToken: undefined,
            blameEmailInput: '',
            blameLoading: false
          });
        } else {
          this.setState({
            blameLoading: false,
            blameCaptchaError: error.message
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    return (
      <section
        style={{
          borderBottom: 'none',
          borderTop: '2px solid #c1c1c1',
          marginTop: '20px'
        }}
      >
        <Container modifier="contentWidthOverlay">
          <h2>Antworte auf diesen Beitrag</h2>

          <div style={{ padding: this.state.windowWidth > 768 ? '0 80px' : 0 }}>
            <SlideToggle
              collapsed={true}
              duration={800}
              noDisplayStyle
              render={({ onToggle, setCollapsibleElement, toggleState, progress }) => (
                <div style={{ marginBottom: '20px' }}>
                  {this.props.interaction && (
                    <Button onClick={onToggle} style={{ marginBottom: '20px' }}>
                      Kommentar schreiben
                    </Button>
                  )}
                  <div style={{ overflow: 'hidden' }} ref={setCollapsibleElement}>
                    <textarea value={this.state.ownComment} onChange={this.updateOwnComment} />
                    <Recaptcha
                      elementID={`${this.props.slug}--create-comment`}
                      style={{
                        display: 'inline-block',
                        width: '100%'
                      }}
                      expiredCallback={() => {
                        this.setState({ captchaToken: null });
                      }}
                      verifyCallback={token => {
                        this.setState({ captchaToken: token });
                      }}
                    />
                    {this.state.captchaError && (
                      <span
                        style={{
                          display: 'block',
                          marginBottom: '15px',
                          color: 'red'
                        }}
                      >
                        {this.state.captchaError}
                      </span>
                    )}
                    <Button
                      onClick={this.submitComment}
                      style={{ marginRight: '20px' }}
                      modifier={this.state.isSending && 'loading'}
                      disabled={this.state.isSending}
                    >
                      {this.state.isSending && <Spinner />}
                      Absenden
                    </Button>
                    <Button onClick={onToggle}>Abbrechen</Button>
                  </div>
                </div>
              )}
            />

            {this.state.comments.map(e => (
              <Comment
                key={e.slug}
                captchaElementID={`${e.slug}--blame-comment`}
                captchaError={this.state.blameCaptchaError}
                captchaExpiredCallback={() => {
                  this.setState({ blameCaptchaToken: null });
                }}
                captchaVerifyCallback={token => {
                  this.setState({
                    blameCaptchaToken: token
                  });
                }}
                emailInput={this.state.blameEmailInput}
                onEmailInputChange={evt => {
                  this.setState({ blameEmailInput: evt.target.value });
                }}
                blameDialogOpen={e.slug === this.state.openBlameDialogSlug}
                onLike={() => this.likeComment(e.slug)}
                onBlameInit={() => {
                  this.setState({
                    openBlameDialogSlug: e.slug,
                    blameReason: '',
                    blameCaptchaToken: undefined,
                    blameCaptchaError: undefined,
                    blameEmailInput: '',
                    blameLoading: false
                  });
                }}
                onBlameClose={() => {
                  this.setState({ openBlameDialogSlug: '' });
                }}
                blameReason={this.state.blameReason}
                onBlameReasonChange={evt => {
                  this.setState({ blameReason: evt.target.value });
                }}
                onSubmit={evt => {
                  evt.preventDefault();
                  this.blameComment();
                }}
                loading={this.state.blameLoading}
                {...e}
              />
            ))}
          </div>
        </Container>
      </section>
    );
  }
}
