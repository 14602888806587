import React from 'react';
import SEO from '../components/seo';
import { ContentPage } from '../components/contentpage';

const Sicherheitshinweise = () => (
  <ContentPage>
    <SEO title="Sicherheitshinweise" />
    <h2>Hinweise und Vorsichtsmaßnahmen</h2>
    <p>
      Mit dieser Plattform möchten wir Menschen dabei unterstützen, Gutes zu tun. Damit das auch
      funktioniert und wir nicht alles noch schlimmer machen, hier ein paar Tipps und Hinweise:
    </p>
    <ul>
      <li>
        So gut die Hilfe auch gemeint ist, achtet natürlich weiterhin auf die von Bundes- und
        Landesregierungen vorgegebenen Sicherheitsmaßnahmen. Haltet den Sicherheitsabstand ein,
        übergebt Einkäufe und Bezahlung an der Haus-/Wohnungstür und haltet dabei Abstand etc.
      </li>
      <li>
        Schützt euch und andere! Natürlich macht es derzeit keinen Sinn, wenn eine Person zu
        verschiedenen Zeiten in mehreren Haushalten auf unterschiedliche Kinder aufpasst! Sucht euch
        lieber eine Person oder einen Haushalt denen ihr längerfristig helfen möchtet.
      </li>
      <li>
        Es bringt euch selbst in Gefahr, wenn ihr nun häufiger als nötig einkaufen geht, um anderen
        zu helfen. Bietet eure Hilfe also lieber an, wenn ihr ohnehin einkaufen wollt.
      </li>
      <li>
        Informiert euch, wann es sinnvoll ist, wie zu helfen. Einen guten Eindruck vermittelt z.B.
        dieser Artikel{' '}
        <a
          href="https://www.zeit.de/zeit-magazin/leben/2020-03/nachbarschaftshilfe-coronavirus-infektionskette-solidaritaet-netzwerkforschung"
          target="_blank"
        >
          LINK
        </a>
      </li>
    </ul>
    <h2>Ihr solltet auf keinen Fall persönlich helfen, wenn</h2>
    <ul>
      <li>ihr Teil einer Risikogruppe seid</li>
      <li>ihr selbst infiziert seid oder zumindest einzelne Symptome zeigt</li>
      <li>ihr euch in Quarantäne befindet.</li>
      <li>einer der oben beschriebenen Punkte auf Menschen aus eurem Haushalt zutrifft</li>
    </ul>
    <p>
      Wenn ihr trotzdem helfen möchtet, bestellt Essen bei lokalen Restaurants, lasst euch vom Café
      um die Ecke einen Kuchen backen oder kauft Gutscheine der lokalen Kulturstätten! Auch das ist
      derzeit dringend benötigte Hilfe.
    </p>
    <h2>Schwarze Schafe gibt es leider auch in Zeiten der Solidarität</h2>
    <p>
      Leider mehren sich unter dem Deckmantel der Nachbarschaftshilfen auch Betrugsfälle. Wir
      stellen hier zwar die Plattform bereit, haben aber nicht die Möglichkeit, die Angebote und
      Gesuche zu prüfen.
    </p>
    <ul>
      <li>
        Die Polizei rät allen, die Hilfe annehmen, ein gesundes Misstrauen an den Tag zu legen.
      </li>
      <li>Lasst niemanden in eure Wohnung, dem oder der Ihr nicht vertraut!</li>
      <li>
        Vereinbart eine sinnvolle und für beide Seiten sichere Art, bspw. Geld gegen Einkäufe an der
        Wohnungstür zu tauschen etc.
      </li>
      <li>Meldet Betrugsfälle oder -versuche unbedingt der Polizei!</li>
      <li>
        Weitere Informationen hierzu gibt es bspw.{' '}
        <a
          href="https://www.ndr.de/nachrichten/niedersachsen/hannover_weser-leinegebiet/Polizei-warnt-vor-schamlosen-Corona-Betruegern,trickbetrueger134.html"
          target="_blank"
        >
          hier
        </a>
      </li>
    </ul>
    <p>
      Wenn wir alle gemeinsam darauf achten, die Krise mit gut gemeinten Aktionen nicht noch
      schlimmer zu machen, schaffen wir es gemeinsam durch diese schwierige Zeit. Danke für eure
      Hilfe!
    </p>
  </ContentPage>
);

export default Sicherheitshinweise;
