import React from 'react';
import SEO from '../components/seo';
import { ContentPage } from '../components/contentpage';
import { WsLogo } from '../components/icons/ws-logo';
import { PkLogo } from '../components/icons/pk-logo';

const AboutUs = () => (
  <ContentPage>
    <SEO title="Über uns" />
    <h2>Info</h2>
    <p>
      Die Corona-Pandemie bringt neben sehr viel Negativem auch einige positive Aspekte hervor:
      allen voran die Solidarität zwischen denen, die Hilfe benötigen und denen, die helfen können.
      Wir, die beiden Unternehmen PLAN:KOOPERATIV und Webschuppen, möchten unseren Teil dazu
      beitragen, diese Hilfe noch besser zu koordinieren und Menschen im virtuellen Raum
      zusammenzubringen. Seit vielen Jahren arbeiten wir sehr eng bei der Entwicklung von
      Plattformen für online-Beteiligungen zusammen. Nun haben wir uns dazu entschieden, eine solche
      Plattform gemeinsam umzubauen und sie allen Menschen zur Verfügung zu stellen, die aktuell auf
      Hilfe angewiesen sind oder gerne selber helfen möchtet.
    </p>
    <p>
      Uns war es wichtig, nicht nur den Bereich der Nachbarschaftshilfe abzudecken, für den es
      bereits sehr gute Angebote gibt. Wir wollten auch dem lokalen Einzelhandel, lokalen
      Kulturhäusern und ähnlichen die Möglichkeit geben, ihre Angebote auf der Seite zu
      veröffentlichen. Gerade die vielen Restaurants, Cafés und kleinen Kulturbetriebe sind gerade
      massiv bedroht. Es ist nun sehr wichtig, Ihnen die Möglichkeiten zu geben, ihre kreativen
      Lösungsansätze wie Lieferdienste und Gutscheinangebote zu verbreiten.
    </p>
    <p>
      Zwischen der ersten Idee und der Umsetzung dieser Plattform sind viele ähnliche Angebote
      online gegangen. Wir möchten ausdrücklich betonen, dass hierbei keinerlei Konkurrenzdenken
      aufkommen darf, sondern es ein schönes Zeichen ist, dass viele Menschen ähnliche Gedanken
      hatten. Solltest Du Betreiber*in oder Initiator*in einer sochen Plattform sein und
      irgendwelche Synergieeffekte sehen, melde Dich gerne bei uns!
    </p>
    <p
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around'
      }}
    >
      <a href="https://www.webschuppen.com" target="_blank">
        <WsLogo width={250} />
      </a>
      <a href="https://www.plankooperativ.de" target="_blank">
        <PkLogo width={250} />
      </a>
    </p>
    <br />
    <h3 style={{ textAlign: 'center' }}>Mit freundlicher Unterstützung von</h3>
    <p
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-around'
      }}
    >
      <a href="https://danielgerth.jimdofree.com/%C3%BCber-mich/" target="_blank">
        <img
          src={require('../images/danielgerth-logo.png')}
          alt="Daniel Gerth"
          style={{ width: 150 }}
        ></img>
      </a>
    </p>
  </ContentPage>
);

export default AboutUs;
