import React, { Component } from 'react';
import Layout from '../components/layout';
import { Link } from '@reach/router';
import { Sidebar } from '../components/sidebar';
import sidebarStyle from '../components/sidebar.module.scss';
import { Arrow } from '../components/arrow';
import { ArrowDown } from '../components/icons/arrow-down';
import stylesArrow from '../templates/variant-page.module.scss';
import { CloseButton } from '../components/closeButton';
import { getPosts, likePost, blamePost, contactPost } from '../api';
import { PostCreate } from '../components/post-create';
import { MapboxMap } from '../components/mapbox-map';
import { Tile } from '../components/post-tile';
import tileStyle from '../components/tile.module.scss';
import { PostDetail } from '../components/post-detail';
import { Footer } from '../components/footer';
import filterStyle from '../components/filter.module.scss';
import { graphql, StaticQuery, navigate } from 'gatsby';
import { Button } from '../components/button';
import withLocation from '../components/with-location';
import { validatePost, deletePost, setPostVisibility } from '../api/post';
import WarningPost from '../components/warning-post';
import { containsAll, add, get } from '../api/localstorage-provider';
import PopupDialog from '../components/popup-dialog';
import { UserContext } from '../context/UserContext';
import { Recaptcha } from '../components/recaptcha';
import SEO from '../components/seo';
import { ToggleButton } from '../components/ToggleButton';
import inside from 'point-in-polygon';
import { WindowSizeContext } from '../context/WindowSizeContext';

let Marker;

class PostList extends Component {
  inputRef = React.createRef();

  constructor(props) {
    super(props);

    const data = props.data.allDataJson.edges[0].node;
    const postCreatePage = props.data.postCreatePage.edges[0].node;

    // use the post-create page to find the category module to determine all possible categories
    const categories = postCreatePage.content.find(module => module.type === 'module-post-category')
      .items;

    this.state = {
      allPosts: [],
      posts: [],
      filters: categories.map(e => e.name),
      error: undefined,
      openDetail: undefined,
      blamedPosts: new Set(),
      blamePost: undefined,
      contactPost: undefined,
      initial: true,
      hasFilterBeenUsed: false,
      createOpen: false,
      blameCaptchaToken: undefined,
      blameCaptchaError: undefined,
      contactCaptchaToken: undefined,
      contactCaptchaError: undefined,
      isSafari: false,
      hoveredMarker: undefined,
      updateMapCenter: false,
      updateZoom: false,
      interaction: true,
      sideBarState: 'closed',
      variant: '/',
      visiblePolygon: [],
      /** @type {[number, number]} */
      center: [data.map.lat, data.map.lng],
      /** @type {[number, number]} */
      centerStart: [data.map.lat, data.map.lng],
      /** @type {number} */
      zoom: data.map.zoom,
      mapStyle: data.map.styles[0],
      context: {
        ...data,
        categories: categories.reduce((acc, curr) => {
          acc[curr.name] = curr;
          return acc;
        }, {}),
        postCreateContent: postCreatePage.content
      },
      arrowVisible: true,
      contactSuccess: false,
      loading: true,
      mapLoaded: false
    };

    this.hoverTimeout = undefined;
  }

  componentDidMount() {
    const ReactMapboxGl = require('react-mapbox-gl');
    Marker = ReactMapboxGl.Marker;

    if (this.props.search.delete !== undefined) {
      deletePost({ slug: this.props.search.slug, token: this.props.search.delete })
        .then(([res, err]) => {
          if (err !== null) {
            alert(`Ihr Beitrag ${res.title} wurde erfolgreich gelöscht.`);
          }
          this.getPostList();
        })
        .catch(console.error);
    } else if (this.props.search.token !== undefined) {
      validatePost({ slug: this.props.search.slug, token: this.props.search.token }).then(() => {
        this.getPostList();
      });
    } else {
      this.getPostList();
    }

    this.setState({
      isSafari:
        navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0
    });
  }

  componentWillUnmount() {
    clearTimeout(this.hoverTimeout);
  }

  toggleAllFilter = () => {
    this.state.filters.length > 0
      ? this.setState({ hasFilterBeenUsed: false, filters: [] })
      : this.setState({
          hasFilterBeenUsed: false,
          filters: Object.keys(this.state.context.categories)
        });
  };

  toggleFilter = categoryName => {
    if (this.state.hasFilterBeenUsed) {
      this.setState({
        filters: this.state.filters.includes(categoryName)
          ? this.state.filters.filter(e => e !== categoryName)
          : [...this.state.filters, categoryName]
      });
      return;
    }

    this.setState({ hasFilterBeenUsed: true, filters: [categoryName] });
  };

  getActivePosts = (allPosts, currentVariant, polygon) => {
    return (allPosts || []).filter(
      e =>
        (currentVariant === '/' || e.variant === currentVariant) && this.isVisibleInMap(e, polygon)
    );
  };

  getPostList = () => {
    this.setState({
      updateZoom: true,
      updatePosition: true
    });

    getPosts({ variant: '/' }).then(([posts, error]) => {
      if (posts) {
        this.setState({
          allPosts: posts.items,
          posts:
            this.getActivePosts(posts.items, this.state.variant, this.state.visiblePolygon) || [],
          error,
          loading: false
        });
        if (this.state.openDetail) {
          this.setState({ openDetail: this.state.posts[this.state.openIndex] });
        }

        if (this.props.search.slug !== undefined && this.state.initial === true) {
          const post = posts.items.find(({ slug }) => slug === this.props.search.slug);

          if (Boolean(post)) {
            this.setState({ openDetail: post, initial: false });
          }
        }
      }
    });
  };

  hoverMarker = slug => event => {
    clearTimeout(this.hoverTimeout);
    this.hoverTimeout = setTimeout(() => {
      // TODO: move this into state instead of doing it by hand
      const element = document.getElementById(`list-${slug}`);
      element.style.outline = '3px solid #0095db';
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }, 1000);
  };

  hoverEndMarker = slug => event => {
    clearTimeout(this.hoverTimeout);
    const element = document.getElementById(`list-${slug}`);
    element.style.outline = 'none';
  };

  openDetail = (item, index, interaction) => e => {
    if (e.target.classList && e.target.classList.contains('likeButton')) {
      if (interaction) this.likePost({ slug: item.slug });
    } else {
      this.setState({ openDetail: item });
      this.setState({ openIndex: index });
    }
    this.setState({ interaction: interaction });
  };

  likePost = ({ slug }) => {
    likePost({ slug: slug }).then(([post, error]) => {
      if (!error) {
        this.getPostList();
      }
    });
  };

  blamePost = ({ slug }) => {
    this.setState({ blamePost: slug });
  };

  contactPost = ({ slug }) => {
    this.setState({ contactPost: slug });
  };

  submitBlame = event => {
    event.preventDefault();

    const reasonField = event.target.querySelector('[name="reason"]');
    const emailField = event.target.querySelector('[name="email"]');

    const formData = new FormData(event.target);

    if (this.state.blameCaptchaToken === null) {
      this.setState({
        blameCaptchaError: 'Das Captcha ist abgelaufen. Bitte benutzen Sie es erneut.'
      });
      return;
    } else if (this.state.blameCaptchaToken === undefined) {
      this.setState({
        blameCaptchaError: 'Bitte benutzen Sie das Captcha-Element.'
      });
      return;
    }

    if (!Boolean(formData.get('reason'))) {
      reasonField.classList.add('error');
      return;
    }

    const sendData = {
      slug: this.state.blamePost,
      email: formData.get('email'),
      reason: formData.get('reason'),
      captchaToken: this.state.blameCaptchaToken
    };

    this.setState({ isBlaming: true, blameCaptchaError: undefined });

    blamePost(sendData)
      .then(([post, error]) => {
        if (!error) {
          reasonField.classList.remove('error');
          reasonField.value = '';
          emailField.value = '';

          const blamedPosts = this.state.blamedPosts;
          blamedPosts.add(this.state.blamePost);
          this.setState({
            blamedPosts: blamedPosts,
            blamePost: undefined,
            isBlaming: undefined,
            blameCaptchaToken: undefined,
            blameCaptchaError: undefined
          });
        } else {
          this.setState({
            blameCaptchaError: error.message,
            isBlaming: undefined
          });
        }
      })
      .catch(console.error);
  };

  submitContact = event => {
    event.preventDefault();

    const messageBodyField = event.target.querySelector('[name="messageBody"]');
    const emailField = event.target.querySelector('[name="email"]');
    const phoneField = event.target.querySelector('[name="phone"]');

    const formData = new FormData(event.target);

    if (this.state.contactCaptchaToken === null) {
      this.setState({
        contactCaptchaError: 'Das Captcha ist abgelaufen. Benutz es bitte erneut.'
      });
      return;
    } else if (this.state.contactCaptchaToken === undefined) {
      this.setState({
        contactCaptchaError: 'Benutz bitte das Captcha-Element.'
      });
      return;
    }

    if (!Boolean(formData.get('messageBody'))) {
      messageBodyField.classList.add('error');
      return;
    }

    const sendData = {
      slug: this.state.contactPost,
      email: formData.get('email'),
      phone: formData.get('phone'),
      messageBody: formData.get('messageBody'),
      captchaToken: this.state.contactCaptchaToken
    };

    this.setState({ isContacting: true, blameCaptchaError: undefined });

    contactPost(sendData)
      .then(([post, error]) => {
        if (!error) {
          messageBodyField.classList.remove('error');
          messageBodyField.value = '';
          emailField.value = '';
          phoneField.value = '';

          this.setState({
            contactPost: undefined,
            isContacting: undefined,
            contactCaptchaToken: undefined,
            contactCaptchaError: undefined,
            contactSuccess: true
          });
        } else {
          this.setState({
            contactCaptchaError: error.message,
            isContacting: undefined
          });
        }
      })
      .catch(console.error);
  };

  toggleVisibility = (item, user) => {
    if (user.loggedIn && user.roles.includes('ROLE_ADMIN')) {
      setPostVisibility({
        slug: item.slug,
        visible: !item.visible
      }).then(([post, error]) => {
        if (!error) {
          this.getPostList();
        }
      });
    }
  };

  isVisible = e => {
    const { categories } = this.state.context;
    return categories[e.category] !== undefined && this.state.filters.includes(e.category);
  };

  isVisibleInMap = (post, polygon) => {
    const { latitude, longitude } = post.coordinates;

    return inside([longitude, latitude], polygon);
  };

  onVisibleAreaChanged = ({ zoom, center, polygon }) => {
    const newState = {};

    if (polygon && JSON.stringify(this.state.visiblePolygon) !== JSON.stringify(polygon)) {
      newState.visiblePolygon = polygon;
      newState.posts = this.getActivePosts(this.state.allPosts, this.state.variant, polygon);
    }

    if (zoom && zoom !== this.state.zoom) {
      newState.zoom = zoom;
    }

    if (center && center[0] !== this.state.center[0] && center[1] !== this.state.center[1]) {
      newState.center = center;
    }

    if (Object.keys(newState).length > 0) {
      this.setState({ ...newState, mapLoaded: true }, () => {
        // fixme
        this.forceUpdate();
      });
    }
  };

  onSidebarScroll = () => {
    this.setState({ arrowVisible: false });
  };

  render() {
    const {
      colorCode,
      textHighlightColor,
      title,
      map,
      mapFeatures: shape,
      categories,
      postCreateContent
    } = this.state.context;

    const markers = {};
    const noFilters = this.state.filters.length === 0;

    const mapModule = postCreateContent.find(function(el) {
      return el.type === 'module-post-map';
    });

    const { isSafari, sideBarState, center, zoom, mapStyle, arrowVisible } = this.state;

    return (
      <Layout>
        <SEO title={title} />
        <WindowSizeContext.Consumer>
          {({ viewport, showMapH }) => {
            return (
              <>
                <span className={showMapH ? '' : 'mapNotVisible'}>
                  <MapboxMap
                    inputRef={this.inputRef}
                    center={center}
                    zoom={viewport === 'mobile' ? 4 : viewport === 'tablet' ? 4 : zoom}
                    minZoom={map.minZoom}
                    maxZoom={map.maxZoom}
                    loading={!(this.state.posts.length && this.state.posts.length > 0)}
                    onMapStyleChange={link => {
                      this.setState({ mapStyle: map.styles.find(e => e.link === link) });
                    }}
                    mapStyle={mapStyle}
                    mapFeatures={shape}
                    // updateZoom={this.state.updateZoom}
                    // updateZoom={false}
                    // updatePosition={this.state.updateMapCenter}
                    width={'100%'}
                    height={showMapH ? (viewport === 'mobile' ? '300px' : '100%') : 0}
                    // style={{
                    //   maxWidth:
                    //     sideBarState === 'full'
                    //       ? 'calc(100% - 1370px)'
                    //       : sideBarState === 'open'
                    //       ? 'calc(100% - 700px)'
                    //       : '100%',
                    //   minWidth:
                    //     sideBarState === 'full' ? '20%' : sideBarState === 'open' ? '60%' : '100%',
                    //   width: '100%'
                    // }}
                    style={{
                      width: viewport === 'mobile' ? '100%' : viewport === 'tablet' ? '50%' : '60%'
                    }}
                    onVisibleAreaChanged={this.onVisibleAreaChanged}
                    onResult={this.onResult}
                  >
                    {this.state.posts
                      .filter(({ coordinates, category }) => {
                        return (
                          !!coordinates &&
                          coordinates.latitude &&
                          coordinates.longitude &&
                          categories[category] !== undefined &&
                          this.state.filters.includes(category)
                        );
                      })
                      .map(item => {
                        const marker = (
                          <Marker
                            anchor={'bottom'}
                            coordinates={[item.coordinates.longitude, item.coordinates.latitude]}
                            onMouseEnter={this.hoverMarker(item.slug)}
                            onMouseLeave={this.hoverEndMarker(item.slug)}
                            key={`marker-${item.slug}`}
                            onClick={this.openDetail(item, undefined, true)}
                            style={{
                              cursor: 'pointer',
                              opacity: item.visible ? 1 : 0.5,
                              filter: isSafari ? 'none' : `grayscale(${item.visible ? 0 : 1})`
                            }}
                            className={
                              this.state.hoveredMarker === item.slug ? 'active-marker' : ''
                            }
                          >
                            <img
                              src={
                                item.variant === '/offer'
                                  ? require(`../images/${
                                      categories[item.category].marker.activeFile
                                    }`)
                                  : require(`../images/${categories[item.category].marker.file}`)
                              }
                              alt="Marker"
                            />
                          </Marker>
                        );
                        markers[item.slug] = marker;

                        return marker;
                      })}
                  </MapboxMap>
                </span>
                <Sidebar
                  onScroll={this.onSidebarScroll}
                  className={`${showMapH ? '' : 'sidebarMapNotVisible'}`}
                  onSidebarStateChange={currentSideBarState => {
                    this.setState({ sideBarState: currentSideBarState });
                  }}
                  render={currentSideBarState => {
                    const baseTileStyle = `${tileStyle.tile} ${tileStyle[currentSideBarState]}`;
                    return (
                      <>
                        {arrowVisible && (
                          <ArrowDown
                            className={stylesArrow.arrowDown}
                            style={{
                              width: '180px',
                              height: '180px',
                              position: 'absolute',
                              bottom: '30px',
                              left: 'calc(50% - 90px)',
                              zIndex: '10'
                            }}
                          />
                        )}
                        <h2
                          style={{
                            backgroundColor: colorCode,
                            marginBottom: '24px',
                            padding: '14px 1rem 3px',
                            color: textHighlightColor || '#000',
                            fontSize: '34px'
                          }}
                        >
                          <b>Beiträge erkunden</b>
                        </h2>
                        <div
                          id="geocoder-map"
                          className="geocoder"
                          ref={this.inputRef}
                          style={{ marginBottom: '15px', zIndex: 20, position: 'relative' }}
                        />
                        {this.state.loading || !this.state.mapLoaded ? (
                          <div className={'loader'} style={{ marginTop: 100 }}></div>
                        ) : (
                          <>
                            <ToggleButton
                              isOnPostCreatePage={false}
                              onClick={type => {
                                this.setState({
                                  variant: type,
                                  posts: this.getActivePosts(
                                    this.state.allPosts,
                                    type,
                                    this.state.visiblePolygon
                                  )
                                });
                              }}
                              style={{ marginBottom: '15px', justifyContent: 'left' }}
                              selectedType={this.state.variant}
                              unselectable={true}
                            />
                            <CategoryFilter
                              noFilters={noFilters}
                              categories={categories}
                              filters={this.state.filters}
                              onCategoryToggle={this.toggleFilter}
                              onCategoryToggleAll={this.toggleAllFilter}
                              colorCode={colorCode}
                              textHighlightColor={textHighlightColor}
                              posts={this.state.posts}
                            />

                            <div key={'post-tile-wrapper'} className={tileStyle.tileWrapper}>
                              <UserContext.Consumer>
                                {user => {
                                  const showPostCreate = true;
                                  const isAdmin =
                                    user.loggedIn && user.roles.includes('ROLE_ADMIN');

                                  return [
                                    <PostCreate
                                      center={[map.lat, map.lng]}
                                      colorCode={colorCode}
                                      textHighlightColor={textHighlightColor}
                                      sidebar={currentSideBarState}
                                      key={'post-create-button'}
                                      shape={shape}
                                      onPostCreated={this.getPostList}
                                      content={postCreateContent}
                                      onToggleOpenState={open =>
                                        this.setState({ createOpen: open })
                                      }
                                    />
                                  ]
                                    .concat(
                                      this.state.posts.filter(this.isVisible).map((item, index) => {
                                        const id = `list-${item.slug}`;
                                        let renderList = [
                                          <Tile
                                            variant={item.variant}
                                            key={id}
                                            id={id}
                                            post={item}
                                            className={
                                              baseTileStyle +
                                              (item.visible || isSafari
                                                ? ''
                                                : ` ${tileStyle.hidden}`)
                                            }
                                            onClick={this.openDetail(item, index, showPostCreate)}
                                            category={categories[item.category]}
                                            like={
                                              showPostCreate
                                                ? () => this.likePost({ slug: item.slug })
                                                : () => {}
                                            }
                                            toggleVisibility={() =>
                                              this.toggleVisibility(item, user)
                                            }
                                            isAdmin={isAdmin}
                                            onMouseEnter={() =>
                                              this.setState({
                                                updateZoom: false,
                                                hoveredMarker: item.slug
                                              })
                                            }
                                            onMouseLeave={() =>
                                              this.setState({
                                                hoveredMarker: undefined
                                              })
                                            }
                                            interaction={showPostCreate}
                                          />
                                        ];
                                        // if ((index + 1) % 10 === 0) {
                                        //   renderList.push(
                                        //     <PostCreate
                                        //       center={[map.lat, map.lng]}
                                        //       colorCode={colorCode}
                                        //       textHighlightColor={textHighlightColor}
                                        //       sidebar={currentSideBarState}
                                        //       key={`post-create-button-list-${index}`}
                                        //       shape={shape}
                                        //       onPostCreated={this.getPostList}
                                        //       content={postCreateContent}
                                        //       onToggleOpenState={open => this.setState({ createOpen: open })}
                                        //     />
                                        //   );
                                        // }
                                        return renderList;
                                      })
                                    )
                                    .filter(Boolean);
                                }}
                              </UserContext.Consumer>
                            </div>
                          </>
                        )}
                      </>
                    );
                  }}
                />
                {this.state.openDetail && categories[this.state.openDetail.category] !== undefined && (
                  <PostDetail
                    {...this.state.openDetail}
                    categoryObj={categories[this.state.openDetail.category]}
                    onClose={() => {
                      this.setState({ openDetail: undefined });
                    }}
                    interaction={this.state.interaction}
                    onLike={() => {
                      this.likePost({ slug: this.state.openDetail.slug });
                    }}
                    onBlame={() => {
                      this.blamePost({ slug: this.state.openDetail.slug });
                    }}
                    onContact={() => {
                      this.contactPost({ slug: this.state.openDetail.slug });
                    }}
                    blamed={this.state.blamedPosts.has(this.state.openDetail.slug)}
                    map={{
                      style:
                        (mapModule && mapModule.map && mapModule.map.style) ||
                        'mapbox://styles/cheffen/cjyk5thfn07yu1dm0v66sfvbg'
                    }}
                  />
                )}
                {Boolean(this.state.blamePost) && (
                  <PopupDialog
                    onClose={() =>
                      this.setState({
                        blamePost: undefined,
                        isBlaming: undefined,
                        blameCaptchaToken: undefined,
                        blameCaptchaError: undefined
                      })
                    }
                    image={require('../images/mail.svg')}
                  >
                    <h2>Diesen Beitrag Melden</h2>
                    <form onSubmit={this.submitBlame}>
                      <label>Warum melden?</label>
                      <textarea name="reason" />
                      <label>E-Mail-Adresse</label>
                      <input type="text" name="email" />
                      <Recaptcha
                        style={{
                          display: 'inline-block',
                          width: '100%'
                        }}
                        expiredCallback={() => {
                          this.setState({ blameCaptchaToken: null });
                        }}
                        verifyCallback={token => {
                          this.setState({ blameCaptchaToken: token });
                        }}
                      />
                      {this.state.blameCaptchaError && (
                        <span style={{ color: 'red', display: 'block' }}>
                          {this.state.blameCaptchaError}
                        </span>
                      )}
                      <Button
                        modifier={this.state.isBlaming && 'loading'}
                        disabled={this.state.isBlaming}
                      >
                        Absenden
                      </Button>
                    </form>
                  </PopupDialog>
                )}

                {this.state.contactSuccess && (
                  <PopupDialog
                    onClose={() => {
                      this.setState({ contactSuccess: false });
                    }}
                  >
                    <h2>Vielen Dank!</h2>
                    Deine Nachricht wurde dem Beitragsersteller übermittelt.
                  </PopupDialog>
                )}

                {Boolean(this.state.contactPost) && (
                  <PopupDialog
                    onClose={() =>
                      this.setState({
                        contactPost: undefined,
                        isContacting: undefined,
                        contactCaptchaToken: undefined,
                        contactCaptchaError: undefined
                      })
                    }
                    image={require('../images/mail.svg')}
                  >
                    <h2>Den Beitragsersteller kontaktieren</h2>
                    <form onSubmit={this.submitContact}>
                      <label>Sende eine Nachricht*</label>
                      <textarea name="messageBody" required />
                      <label>Deine E-Mailadresse*</label>
                      <input type="text" name="email" required />
                      <label>Deine Telefonnummer</label>
                      <input type="text" name="phone" />
                      <label style={{ display: 'flex', marginBottom: '20px' }}>
                        <span
                          style={{
                            display: 'inline-block',
                            margin: '0 25px 0 0'
                          }}
                        >
                          <input
                            style={{ minWidth: '20px' }}
                            type="checkbox"
                            name="dataSec"
                            required
                          />
                        </span>
                        <span>
                          Ich habe die{' '}
                          <Link to="/sicherheitshinweise">Hinweise und Vorsichtsmaßnahmen</Link>{' '}
                          gelesen.*
                          <br />
                          <br />
                          Mit dem Absenden dieser Nachricht erklärst Du dich mit den{' '}
                          <Link to="/imprint">AGB</Link> und den{' '}
                          <Link to="/imprint">Nettiquetten</Link> dieser Plattform einverstanden.
                          Wir weisen explizit darauf hin, dass der/die Empfängerin dieser Nachricht
                          Deine E-Mail-Adresse sehen wird und Dir antworten kann.
                        </span>
                      </label>
                      <Recaptcha
                        style={{
                          display: 'inline-block',
                          width: '100%'
                        }}
                        expiredCallback={() => {
                          this.setState({ contactCaptchaToken: null });
                        }}
                        verifyCallback={token => {
                          this.setState({ contactCaptchaToken: token });
                        }}
                      />
                      {this.state.contactCaptchaError && (
                        <span style={{ color: 'red', display: 'block' }}>
                          {this.state.contactCaptchaError}
                        </span>
                      )}
                      <Button
                        modifier={this.state.isContacting && 'loading'}
                        disabled={this.state.isContacting}
                      >
                        Absenden
                      </Button>
                    </form>
                  </PopupDialog>
                )}
              </>
            );
          }}
        </WindowSizeContext.Consumer>
        <Footer />
      </Layout>
    );
  }
}

function CategoryFilter({
  noFilters,
  colorCode,
  textHighlightColor,
  categories,
  posts,
  filters,
  onCategoryToggle,
  onCategoryToggleAll
}) {
  const allActive = filters.length === Object.keys(categories).length;

  return (
    <ul className={filterStyle.list}>
      <li className={`${filterStyle.listItem}${allActive ? ` ${filterStyle.active}` : ''}`}>
        <button
          onClick={e => {
            e.preventDefault();
            onCategoryToggleAll();
          }}
          style={{
            color: allActive ? '#fff' : '#000',
            backgroundColor: allActive ? '#0095db' : 'white'
          }}
        >
          {`Alle Beiträge (${posts.length})`}
        </button>
      </li>
      {Object.keys(categories).map(categoryName => {
        const active = noFilters ? false : filters.includes(categoryName);
        return (
          <li
            key={categoryName}
            className={`${filterStyle.listItem}${active ? ` ${filterStyle.active}` : ''}`}
          >
            <button
              onClick={e => {
                e.preventDefault();
                onCategoryToggle(categoryName);
              }}
              style={{
                color: active ? '#fff' : '#000',
                backgroundColor: active ? '#0095db' : 'white'
              }}
            >
              {categoryName} (
              {
                posts.filter(function(post) {
                  return post.category === categoryName;
                }).length
              }
              )
            </button>
          </li>
        );
      })}
    </ul>
  );
}

export default withLocation(PostList);

export const query = graphql`
  query {
    allDataJson(filter: { page: { eq: "map" } }) {
      edges {
        node {
          title
          subTitle
          colorCode
          textHighlightColor
          map {
            lat
            lng
            minZoom
            maxZoom
            zoom
            styles {
              link
              icon
              title
            }
          }
          mapFeatures {
            fillProperties {
              fill_color
              fill_opacity
            }
            shape
          }
        }
      }
    }

    postCreatePage: allDataJson(filter: { name: { eq: "post-create" } }) {
      edges {
        node {
          name
          content {
            type
            title
            required
            maxchars
            info
            placeholder
            sections {
              text
            }
            dropdownValues
            items {
              name
              image {
                file
              }
              marker {
                file
                activeFile
              }
              description {
                text
              }
            }
            map {
              style
              lat
              lng
              minZoom
              maxZoom
              zoom
            }
          }
        }
      }
    }
  }
`;
