import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Info } from './info';
import { isFunction, isInShape } from '../../utils';
import { token } from '../../token';

import postCreateStyle from '../../components/post-create.module.scss';
import style from './map.module.scss';

// let
// Wrap the require in check for window
let LMap, Marker, GeoJSONLayer, MapboxGeocoder;

require('mapbox-gl/dist/mapbox-gl.css');

export class MapModule extends Component {
  inputRef = React.createRef();

  // handleClick = (map, e) => {
  //   if (isFunction(this.props.onChange)) {
  //     if (
  //       this.props.shape &&
  //       this.props.shape[0] &&
  //       isInShape([e.lngLat.lng, e.lngLat.lat], this.props.shape[0].shape)
  //     ) {
  //       if (isFunction(this.props.handleIsInShape)) this.props.handleIsInShape(true);

  //       this.props.onChange({ latitude: e.lngLat.lat, longitude: e.lngLat.lng });
  //     } else {
  //       if (isFunction(this.props.handleIsInShape)) this.props.handleIsInShape(false);
  //     }
  //   }
  // };

  onMapLoad = map => {
    if (!this.props.mapOnly) {
      const geocoder = new MapboxGeocoder({
        accessToken: token,
        countries: 'de',
        language: 'de',
        placeholder: '12345 Musterstadt',
        marker: false,
        flyTo: false
      });

      geocoder.on('result', ({ result }) => {
        const [lng, lat] = result.center;
        this.props.onChange({ latitude: lat, longitude: lng });
      });

      if (!this.inputRef.current.childElementCount) {
        this.inputRef.current.appendChild(geocoder.onAdd(map));
      }
    }
  };

  componentDidMount() {
    if (typeof window !== `undefined`) {
      const ReactMapboxGl = require('react-mapbox-gl');
      Marker = ReactMapboxGl.Marker;
      GeoJSONLayer = ReactMapboxGl.GeoJSONLayer;
      MapboxGeocoder = require('@mapbox/mapbox-gl-geocoder');
      LMap = ReactMapboxGl.Map({
        accessToken: token,
        minZoom: this.props.minZoom,
        maxZoom: this.props.maxZoom
      });
    }
  }

  render() {
    const { marker, shape } = this.props;
    const icon = require(`../../images/${this.props.markerIcon || 'mapmarker.png'}`);

    return (
      <>
        <h2>
          {!this.props.required && <span className={postCreateStyle.optionalTag}>optional</span>}
          {this.props.title}
        </h2>
        <div
          className={postCreateStyle.sectionLeft}
          style={{ minWidth: this.props.mapOnly ? '100%' : '60%' }}
        >
          <StaticQuery
            query={graphql`
              {
                allDataJson(filter: { page: { eq: "map" } }) {
                  edges {
                    node {
                      map {
                        lat
                        lng
                        minZoom
                        maxZoom
                        zoom
                        styles {
                          link
                          icon
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={data => {
              const { map } = data.allDataJson.edges[0].node;
              const { lng, lat } =
                this.props.center && this.props.center.lat && this.props.center.lng
                  ? this.props.center
                  : map;
              return (
                <>
                  {!this.props.mapOnly && (
                    <div
                      id="geocoder"
                      className="geocoder"
                      ref={this.inputRef}
                      style={{ marginBottom: '30px', zIndex: 20, position: 'relative' }}
                    />
                  )}
                  {LMap && ( // prevent Leaflet from being server side rendered
                    <LMap
                      animate={true}
                      center={{ lng, lat }}
                      zoom={[this.props.zoom || map.zoom || 15]}
                      style={
                        this.props.mapStyle ||
                        map.styles[0].link ||
                        'mapbox://styles/cheffen/cjv2brtuk097i1flrt8b4lv2v'
                      }
                      containerStyle={{ minHeight: 300 }}
                      className={style.map}
                      // onClick={this.handleClick}
                      onStyleLoad={this.onMapLoad}
                    >
                      {marker && marker.latitude && marker.longitude && (
                        <Marker
                          coordinates={{ lat: marker.latitude, lng: marker.longitude }}
                          anchor="bottom"
                          // offset={[0, -50]}
                        >
                          <img src={icon} alt="Marker" />
                        </Marker>
                      )}
                      {shape && shape[0] && (
                        <GeoJSONLayer
                          key={shape[0][0]}
                          linePaint={{
                            'line-color': 'rgb(0, 149, 219)',
                            'line-width': 3
                          }}
                          data={{
                            type: 'Feature',
                            geometry: {
                              type: 'Polygon',
                              coordinates: [shape[0].shape]
                            }
                          }}
                        />
                      )}
                    </LMap>
                  )}
                </>
              );
            }}
          />
        </div>
        {!this.props.mapOnly && (
          <div className={postCreateStyle.sectionRight} anchor="bottom">
            <Info text={this.props.info} />
          </div>
        )}
      </>
    );
  }
}
