import React, { Fragment } from 'react';
import style from './post-create.module.scss';
import shareStyle from './post-detail-share.module.scss';
import { Container } from './container';
import { CloseButton } from './closeButton';
import StyledScrollbars from './styled-scrollbars';
import postStyle from '../components/post-tile.module.scss';
import { MapModule } from '../modules/post/map';
import { CommentSection } from './comment-section';
import { Button } from './button';
import { Spinner } from './spinner';
import { BlameIcon } from './icons/blame-icon';
import { InfoIcon } from './icons/info';
import { propTypes } from 'react-recaptcha';

const API_ENDPOINT = process.env.GATSBY_API_URL;

export const PostDetail = ({
  onClose,
  onSend,
  onLike,
  onBlame,
  onContact,
  category,
  comments,
  content,
  coordinates,
  created,
  image,
  blamed,
  liked,
  likes,
  own,
  slug,
  tags,
  title,
  user,
  variant,
  visible,
  categoryObj,
  alias,
  map,
  modifier,
  isSending = false,
  errors = [],
  interaction
}) => {
  const isPreview = modifier === 'preview';

  const fullUrl = `${API_ENDPOINT}/map?slug=${slug}`;

  return (
    <>
      <div className={style.postWrapper} style={{ textAlign: 'initial' }}>
        <div className={style.post}>
          <div className={style.closeRow}>
            <Container modifier="contentWidthOverlay">
              <CloseButton onClick={onClose} />
            </Container>
          </div>
          <div className={style.contentwrapper}>
            <StyledScrollbars>
              <Container modifier="contentWidthOverlay">
                <section
                  className={`${style.noBorder} ${style.preview}`}
                  style={{
                    marginBottom: 50,
                    float: 'left',
                    width: '60%',
                    position: 'relative',
                    paddingLeft: '80px'
                  }}
                >
                  {/* {Boolean(categoryObj) && (
                    <img
                      src={
                        categoryObj.image.file.startsWith('http')
                          ? categoryObj.image.file
                          : require('../images/' + categoryObj.image.file)
                      }
                      width="60"
                      height="60"
                      alt={categoryObj.title}
                      style={{ position: 'absolute', left: '0', top: '45px' }}
                    />
                  )} */}
                  <h2>{title}</h2>
                  <div style={{ marginBottom: '20px' }}>{content}</div>
                  {Boolean(alias) && (
                    <div style={{ marginBottom: '3px', color: 'rgb(204,204,204)' }}>
                      <b>Alias:</b> <span>{alias}</span>
                    </div>
                  )}
                  <div style={{ marginBottom: '30px', color: 'rgb(204,204,204)' }}>
                    <b>Kategorie:</b> <span>{category}</span>
                  </div>
                  <div className={style.iconbar}>
                    {/* {Boolean(slug) && (
                      <span className={style.chiffre}>
                        <strong>Chiffre:</strong> {slug}
                      </span>
                    )} */}
                    {!blamed && !isPreview && (
                      <span className={style.blame} onClick={onBlame}>
                        <BlameIcon />
                      </span>
                    )}
                    {!isPreview && (
                      <>
                        <div className={`${shareStyle.button} ${style.shareicon}`}>
                          <div className={shareStyle.popover} style={{ display: 'none' }}>
                            <a
                              href={`mailto:?&subject=Ein%20Beitrag%20für%20Dich%20auf%20brauchtdich.org&body=Auf%20der%20Plattform%20www.brauchtdich.org%20habe%20ich%20diesen%20Beitrag%20entdeckt,%20der%20Dir%20gefallen%20könnte:%20${encodeURI(
                                fullUrl
                              )}`}
                              className={shareStyle.link}
                            >
                              &nbsp;
                            </a>
                            <a
                              href={`https://mobile.twitter.com/intent/tweet?text=${encodeURI(
                                fullUrl
                              )}`}
                              target="_blank"
                              className={shareStyle.link}
                              rel="noopener noreferrer"
                            >
                              &nbsp;
                            </a>
                            <a
                              href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                                fullUrl
                              )}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={shareStyle.link}
                            >
                              &nbsp;
                            </a>
                          </div>
                        </div>
                        <span className={style.likeicon}>
                          <span className={postStyle.likeButton}>
                            <div className={postStyle.countBox}>{likes}</div>
                            <img
                              src={require(`../images/heart-${liked ? 'filled' : 'outline'}.png`)}
                              alt="like"
                              className="likeButton"
                              onClick={interaction ? onLike : () => {}}
                            />
                          </span>
                        </span>
                      </>
                    )}
                  </div>
                  {!isPreview && (
                    <div className={style.contactBox}>
                      <Button onClick={onContact}>Kontakt aufnehmen</Button>
                    </div>
                  )}
                </section>
                <section
                  className={`${style.noBorder} ${style.preview}`}
                  style={{ float: 'right', width: '40%' }}
                >
                  {image !== null && typeof image !== 'undefined' && (
                    <img
                      src={isPreview ? image : `${API_ENDPOINT}${image}`}
                      style={{ width: '100%', paddingRight: 15 }}
                      alt="Beispiel Bild"
                    />
                  )}
                  <MapModule
                    required
                    mapStyle={map && map.style}
                    zoom={15}
                    minZoom={15}
                    maxZoom={15}
                    marker={coordinates}
                    markerIcon={Boolean(categoryObj.marker) ? categoryObj.marker.file : undefined}
                    center={{ lng: coordinates.longitude, lat: coordinates.latitude }}
                    mapOnly={true}
                  />
                </section>
              </Container>

              {isPreview ? (
                <Container
                  modifier="contentWidthOverlay"
                  style={{ textAlign: 'left', marginTop: 50, marginBottom: 40 }}
                >
                  <div style={{ color: 'red', marginBottom: 20 }}>
                    {errors.map((err, i) => {
                      return (
                        <Fragment key={i}>
                          <span>{err}</span>
                          <br />
                        </Fragment>
                      );
                    })}
                  </div>
                  <Button onClick={onClose} style={{ marginRight: 20, backgroundColor: 'gray' }}>
                    Bearbeiten / Ändern
                  </Button>
                  <Button onClick={onSend} modifier={isSending && 'loading'}>
                    {isSending && <Spinner />} Beitrag veröffentlichen
                  </Button>
                </Container>
              ) : (
                <CommentSection slug={slug} interaction={interaction} />
              )}
            </StyledScrollbars>
          </div>
        </div>
      </div>
    </>
  );
};
